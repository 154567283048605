import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import StandardBanner from "../../../components/StandardHero/StandardHero";
import VideoFeaturePanel from "../../../components/VideoFeaturePanel/VideoFeaturePanel";
import Benefits from "../../../components/Benefits/Benefits";
import RelatedServices from "../../../components/RelatedServices/RelatedServices";
import Testimonials from "../../../components/Testimonials/Testimonials";

const Consultants = ({
  data,
  data: {
    banner,
    banner: {
      featurePanel,
      benefits: benefitsData,
      services: relatedServicesData,
      testimonialsPanel: testimonialsData,
      Articles: articlesData,
      topics,
      client_types,
      filter_types,
    },
  },
}) => {
  const bannerImage =
    banner.hero.image.localFile.childImageSharp.gatsbyImageData;
  const bannerTitle = banner.hero.title;
  const showBreadcrumb = banner.hero.showBreadcrumb;

  const breadcrumbs = [{ name: "WHO WE HELP" }, { name: "CONSULTANTS" }];

  return (
    <Layout
      seo={{
        title: banner.metaData?.pageTitle,
        description: banner.metaData?.metadescription,
        image: bannerImage,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <VideoFeaturePanel data={featurePanel} />
      <Benefits benefitsData={benefitsData} faqLink={data.faqLink} />
      <Testimonials testimonialsData={testimonialsData} />
      <RelatedServices
        relatedServicesData={relatedServicesData}
        articlesData={articlesData}
        tagTypes={filter_types}
        topics={topics}
        client_types={client_types}
      />
    </Layout>
  );
};

export default Consultants;

export const query = graphql`
  {
    banner: strapiSolutionsConsultants {
      topics {
        id
        type
      }
      client_types {
        id
        Type
      }
      filter_types {
        type
      }
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        showBreadcrumb
      }
      metaData {
        pageTitle
        metadescription
      }
      faqLink {
        faq_category {
          category
        }
        text
      }
      Articles {
        articlesHeading
        insights {
          id
        }
        case_studies {
          id
        }
        press_releases {
          id
        }
        events {
          id
        }
      }
      services {
        shortDesc
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
              )
            }
          }
          alternativeText
        }
      }
      benefits {
        Copy
        Title
        Icon {
          localFile {
            publicURL
          }
        }
      }
      testimonialsPanel {
        ColorTheme
        Pattern {
          localFile {
            publicURL
          }
        }
        testimonials {
          Name
          Role
          Copy
          Logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  quality: 100
                  webpOptions: { quality: 100 }
                )
              }
            }
            alternativeText
          }
        }
      }
      featurePanel {
        copy
        heading
        headerImage {
          localFile {
            publicURL
          }
        }
        video {
          video {
            localFile {
              publicURL
            }
          }
          transcript {
            localFile {
              id
              publicURL
            }
          }
        }
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
